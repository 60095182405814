import React from "react";
import Layout from "../components/common/layout/layout";
import { Navigation } from "../components/common/navigation/navigation";
import { Footer } from "../components/sections/footer";
import { HelpContact } from "../components/common/contact/HelpContact";
import { StaticImage } from "gatsby-plugin-image";

import SEO from "../components/common/layout/seo";

const InvalidWebPageHelpPage = () => {
  return (
    <Layout>
      <SEO title="Zorbi can't be opened on this page" />
      <Navigation />
      <section className="-mt-20">
        <div class="flex flex-wrap items-center md:h-screen">
          <div class="flex items-center w-full md:w-1/2 md:h-full py-12 md:py-0 px-20 md:px-0 bg-purple-600">
            <StaticImage
              class="mx-auto lg:max-w-lg"
              src={"../images/metis-assets/illustrations/error2.png"}
              alt="Error Image"
              placeholder="blurred"
            />
          </div>
          <div class="w-full md:w-1/2 px-4 py-16 md:py-0 text-center">
            <div className="mx-auto max-w-xl">
              <h1 class="mb-2 text-4xl text-purple-600 font-bold font-heading">
                We can't open Zorbi on this page
              </h1>
              <h2 class="mb-6 text-4xl font-bold font-heading">
                Sorry about that
              </h2>
              <p class="mb-6 text-blueGray-500 leading-normal">
                There are a few pages that Zorbi can't be opened on, including
                the 'New tab' page and your browser's settings pages. Sorry for
                the inconvenience.
              </p>
            </div>
            <div>
              <a
                class="block sm:inline-block py-4 px-8 mb-4 sm:mb-0 sm:mr-3 text-xs text-white text-center font-semibold leading-none bg-purple-600 hover:bg-purple-700 rounded"
                href="https://zorbi.cards/"
              >
                Go back to Homepage
              </a>
              <a
                class="block sm:inline-block py-4 px-8 text-xs text-blueGray-600 hover:text-blueGray-800 text-center font-semibold leading-none bg-blueGray-200 hover:bg-blueGray-300 rounded"
                href="https://zorbi.app/"
              >
                Go to app
              </a>
            </div>
          </div>
        </div>
      </section>
      <HelpContact title="Need a hand?" bg="bg-blueGray-100" />
      <Footer />
    </Layout>
  );
};

export default InvalidWebPageHelpPage;
